import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Modal } from "@mantine/core";
import styles from "./Product.module.scss";
import Button from "../common/Button";
import { ChevronLeft, ChevronRight } from "react-feather";
import { Link } from "gatsby";
import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/20/solid";
import { TruckIcon } from "@heroicons/react/24/outline";
import { loadStripe } from "@stripe/stripe-js";

const print_description = `Museum-quality poster made on thick matte paper. Add a wonderful accent to your room and office with this poster that are sure to brighten any environment.`;
const original_description = `Original painting on canvas. Add a wonderful accent to your room and office with this painting that are sure to brighten any environment.`;

const Product = ({
  title,
  type,
  images,
  prices,
  defaultSize,
  discount,
  soldOut,
}) => {
  const [selectedSize, setSelectedSize] = useState(defaultSize);
  const [currentPrice, setCurrentPrice] = useState();
  const [originalPrice, setOriginalPrice] = useState();
  const [saved, setSaved] = useState();
  const [featuredImageIndex, setFeaturedImageIndex] = useState(0);
  const stripePromise = loadStripe(
    "pk_live_51KFGiyEzObGDi4xmbD9v7egySbf35V9cYpLFFACpUJTSyc5zngTVtoQHIMmocx2uEJltB67Q53QfNyfSy4jZkmQv00YZn7RXK7"
  );

  let formattedPrices = [];

  formattedPrices = prices.map((price) => {
    const [size, priceValue] = price.split(" - ");
    return { size, price: Number(priceValue) };
  });

  const handleCheckout = async (event) => {
    const stripe = await stripePromise;

    const response = await fetch("/.netlify/functions/checkout", {
      method: "POST",
      // prettier-ignore
      headers: {
        "Content-Type": "application/json"
      },
      // prettier-ignore
      body: JSON.stringify({
        productName: title,
        amount: currentPrice,
        imageUrl: images[0],
        description: print_description,
        coupon: "30OFF"
      })
    });

    const session = await response.json();

    const result = await stripe.redirectToCheckout({
      sessionId: session.id,
    });

    if (result.error) {
      console.error(result.error.message);
    }
  };

  useEffect(() => {
    if (!selectedSize) return;
    const price = formattedPrices.find((item) => item.size === selectedSize)
      .price;
    setOriginalPrice(price.toFixed(0));
    const discountedPrice = price * (1 - discount / 100);
    setCurrentPrice(discountedPrice.toFixed(0));
    const saved = price - discountedPrice;
    setSaved(saved.toFixed(0));
  }, [selectedSize]);

  const handleChangeIndex = (direction) => {
    if (direction === "next") {
      if (featuredImageIndex === images.length - 1) {
        setFeaturedImageIndex(0);
      } else {
        setFeaturedImageIndex(featuredImageIndex + 1);
      }
    } else {
      if (featuredImageIndex === 0) {
        setFeaturedImageIndex(images.length - 1);
      } else {
        setFeaturedImageIndex(featuredImageIndex - 1);
      }
    }
  };

  let touchStartX = 0;
  let touchEndX = 0;

  const handleTouchStart = (event) => {
    touchStartX = event.touches[0].clientX;
  };

  const handleTouchMove = (event) => {
    touchEndX = event.touches[0].clientX;
  };

  const handleTouchEnd = () => {
    if (touchEndX < touchStartX) {
      handleChangeIndex("next");
    }
    if (touchEndX > touchStartX) {
      handleChangeIndex("previous");
    }
  };

  return (
    <>
      <div className={styles.section}>
        <div className={styles.content}>
          <div className={styles.heading}>
            <Button
              href="/"
              variant="minimal"
              leftIcon={<ArrowLeftIcon width={16} />}
            >
              Back
            </Button>
          </div>
          <div className={styles.grid}>
            <div className={styles.gallery}>
              <div
                className={styles.imageContainer}
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
                onTouchEnd={handleTouchEnd}
              >
                <img src={images[featuredImageIndex]} alt={title} />
                {discount && !soldOut && (
                  <div className={styles.sale}>{discount}% off</div>
                )}
                <div
                  className={styles.arrowLeft}
                  onClick={() => handleChangeIndex("previous")}
                >
                  <ChevronLeft />
                </div>
                <div
                  className={styles.arrowRight}
                  onClick={() => handleChangeIndex("next")}
                >
                  <ChevronRight />
                </div>
              </div>
              <div className={styles.thumbnails}>
                {images.map((image, index) => (
                  <img
                    src={image}
                    alt="thumbnail"
                    className={`${styles.thumbnail} ${featuredImageIndex !==
                      index && styles.inactive}`}
                    onClick={() => setFeaturedImageIndex(index)}
                  />
                ))}
              </div>
            </div>
            <div className={styles.rightContainer}>
              <h1>{title}</h1>
              {soldOut ? (
                <p>
                  <span className={styles.currentPrice}>SOLD OUT</span>
                </p>
              ) : type === "original" ? (
                <p>
                  <span className={styles.currentPrice}>
                    {originalPrice} kr
                  </span>
                </p>
              ) : (
                <p>
                  <span className={styles.currentPrice}>{currentPrice} kr</span>
                  {discount && (
                    <span className={styles.originalPrice}>
                      {originalPrice} kr
                    </span>
                  )}
                  {saved && (
                    <span className={styles.saved}>Save {saved} kr</span>
                  )}
                </p>
              )}
              {type === "print" && (
                <p className={styles.description}>{print_description}</p>
              )}
              {type === "original" && (
                <p className={styles.description}>{original_description}</p>
              )}
              {type === "original" && (
                <p>This painting is a one-of-a-kind piece.</p>
              )}
              {type === "print" && <p>Each print is limited to 10 pieces.</p>}
              <div className={styles.optionContainer}>
                <h4>Size: {selectedSize}</h4>
                {type === "print" &&
                  formattedPrices.map((item) => (
                    <span
                      className={`${styles.sizeOption} ${selectedSize ===
                        item.size && styles.selected}`}
                      onClick={() => setSelectedSize(item.size)}
                    >
                      {item.size}
                    </span>
                  ))}
              </div>
              {/* <div className={styles.optionContainer}>
                <h4>Frame: {selectedFrame}</h4>
                {frames.map((size) => (
                  <span
                    className={`${styles.sizeOption} ${selectedFrame === size &&
                      styles.selected}`}
                    onClick={() => setSelectedFrame(size)}
                  >
                    {size}
                  </span>
                ))}
              </div> */}
              <div className={styles.optionContainer}>
                <ul>
                  {type === "print" && <li>Worldwide free shipping</li>}
                  {type === "original" && <li>Located at Fornebu, Norway</li>}
                  {type === "print" && <li>100% ready to hang</li>}
                  {type === "original" && (
                    <li>Shipped with bubble wrap and cardboard</li>
                  )}

                  {type === "print" && <li>Sold without frame</li>}
                  <li>Norwegian unique art</li>
                </ul>
              </div>
              {type === "print" && (
                <div className={styles.buttonContainer}>
                  <Button
                    fullWidth
                    type="button"
                    size="xl"
                    disabled={soldOut}
                    onClick={handleCheckout}
                  >
                    {soldOut ? "Sold out" : "Buy now"}
                  </Button>
                </div>
              )}
              {type === "original" && (
                <div className={styles.buttonContainer}>
                  <Button
                    fullWidth
                    type="button"
                    size="xl"
                    disabled={soldOut}
                    href={
                      "mailto:lweartworks@gmail.com?subject=I want to buy ' + title + '&body=Hi, I would like to buy ' + title + '. Please let me know if it is still available. Thank you!"
                    }
                  >
                    {soldOut ? "Sold out" : "Get in touch"}
                  </Button>
                </div>
              )}
              {type === "print" && (
                <div className={styles.shippingInformation}>
                  <TruckIcon width={24} />
                  <p>
                    Estimated delivery in{" "}
                    <span className={styles.strong}>4-8 business days</span>
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

Product.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

export default Product;
