import React, { useState, useEffect, useRef } from "react";
import styles from "./BestSellers.module.scss";
import Button from "../common/Button";
import { ArrowRightIcon } from "@heroicons/react/20/solid";
import { Link } from "gatsby";

const BestSellers = ({ artworks }) => {
  return (
    <div className={styles.section}>
      <div className={styles.content}>
        {/* <div className={styles.header}>
          <h2>Best Sellers</h2>
        </div> */}
        <div className={styles.grid}>
          {artworks.map((post) => (
            <PostCard
              id={post.id}
              title={post.frontmatter.title}
              soldOut={post.frontmatter.soldOut}
              type={post.frontmatter.type}
              defaultSize={post.frontmatter.defaultSize}
              slug={post.fields.slug}
              images={post.frontmatter.images}
              prices={post.frontmatter.prices}
              discount={post.frontmatter.discount}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export const PostCard = ({
  id,
  title,
  type,
  soldOut,
  defaultSize,
  slug,
  images,
  prices,
  discount,
  small,
}) => {
  const [hover, setHover] = useState(false);

  let priceValues = [];
  let price;
  let currentPrice;

  if (type !== "original") {
    priceValues = prices.map((price) => {
      const [size, priceValue] = price.split(" - ");
      return { original: price, value: Number(priceValue) };
    });

    price = Math.min(...priceValues.map((p) => p.value));
    currentPrice = price * (1 - discount / 100);
  }

  const featuredImage = images[0];

  return (
    <Link to={slug}>
      <div
        className={`${styles.postCard}`}
        onMouseOver={() => setHover(true)}
        onMouseOut={() => setHover(false)}
      >
        <div
          className={`${styles.cardImage} ${hover && styles.hovered} ${small &&
            styles.small} ${soldOut && styles.soldOut}`}
        >
          <img src={featuredImage} alt={title} />
          {discount && type === "print" && !soldOut && (
            <div className={styles.sale}>{discount}% off</div>
          )}
          {soldOut && (
            <div className={styles.soldOutText}>
              Sold
              <br /> Out
            </div>
          )}
        </div>
        <div className={`${styles.cardContent}`}>
          <h3>{title}</h3>
          {soldOut ? (
            <p>
              <span className={styles.currentPrice}>Sold out</span>
            </p>
          ) : (
            <p>
              {type === "original" ? (
                <span className={styles.currentPrice}>{defaultSize}</span>
              ) : (
                <>
                  <span className={styles.currentPrice}>
                    From {currentPrice.toFixed(0)} kr
                  </span>
                  {discount && (
                    <span className={styles.originalPrice}>
                      {price.toFixed(0)} kr
                    </span>
                  )}
                </>
              )}
            </p>
          )}
        </div>
      </div>
    </Link>
  );
};

export default BestSellers;
