import React from "react";
import _get from "lodash/get";
import { graphql } from "gatsby";
import Layout from "../components/common/Layout";
import Product from "../components/singlePost/Product";
import Article from "../components/singlePost/Article";
import CTA from "../components/common/CTA";
import RelatedArtworks from "../components/singlePost/RelatedArtworks";
import MeetTheArtist from "../components/singlePost/MeetTheArtist";
import SecuredPackaging from "../components/singlePost/SecuredPackaging";
import FeaturesSection from "../components/home/FeaturesSection";
import FAQ from "../components/common/FAQ";

export const SinglePostTemplate = ({
  title,
  date,
  topic,
  type,
  soldOut,
  prices,
  defaultSize,
  discount,
  images,
  body,
  artworks,
}) => (
  <main>
    <Product
      title={title}
      type={type}
      soldOut={soldOut}
      images={images}
      topic={topic}
      defaultSize={defaultSize}
      discount={discount}
      prices={prices}
    />

    <RelatedArtworks artworks={artworks} />
    <MeetTheArtist />
    {/* <FeaturesSection /> */}
    <SecuredPackaging />
    {/* <FAQ /> */}
  </main>
);

// Export Default SinglePost for front-end
const SinglePost = ({ data: { post, artworks } }) => {
  const relatedArtworks = artworks.nodes.filter(
    (item) => item.frontmatter.title !== post.frontmatter.title
  );
  return (
    <Layout
      meta={post.frontmatter.meta || false}
      title={post.frontmatter.title || false}
      socialMediaCard={post.frontmatter.featuredImage || false}
    >
      <SinglePostTemplate
        {...post}
        {...post.frontmatter}
        artworks={relatedArtworks.slice(0, 6)}
        body={post.html}
      />
    </Layout>
  );
};

export default SinglePost;

export const pageQuery = graphql`
  ## Query for SinglePost data
  ## Use GraphiQL interface (http://localhost:8000/___graphql)
  ## $id is processed via gatsby-node.js
  ## query name must be unique to this file
  query SinglePost($id: String!) {
    post: markdownRemark(id: { eq: $id }) {
      ...Meta
      html
      id
      headings {
        value
        depth
      }
      frontmatter {
        title
        type
        soldOut
        template
        prices
        discount
        defaultSize
        date(formatString: "MMMM Do, YYYY")
        images
      }
    }

    artworks: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { fileAbsolutePath: { regex: "/artworks/" } }
      limit: 7
    ) {
      nodes {
        id
        frontmatter {
          title
          type
          soldOut
          images
          discount
          prices
          meta {
            title
            description
          }
        }
        fields {
          slug
        }
      }
    }
  }
`;
